/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouteRecordRaw } from 'vue-router';

import OrgsRoutes from './modules/orgs/routes';

export default [
  {
    path: '/sysmaster',
    name: 'sysmaster',
    component: () => import('./layouts/sysmaster.vue'),
    redirect: { name: 'init' },
    children: [
      {
        path: '/sysmaster',
        name: 'sysmaster.home',
        redirect: { name: 'sysmaster.orgs' },
      },
      ...OrgsRoutes,
    ],
  },
] satisfies RouteRecordRaw[];
