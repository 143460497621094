/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouteRecordRaw } from 'vue-router';

import BeetleRoutes from './modules/beetle/routes';
import MantisRoutes from './modules/mantis/routes';
import MeasurementsRoutes from './modules/measurements/routes';
import OrgsRoutes from './modules/orgs/routes';

export default [
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId',
    name: 'properties.property',
    redirect: { name: 'properties.property.dashboard' },
    component: () => import('./layouts/property.vue'),
    children: [
      {
        path: '/property-role/orgs/:orgId/properties/:propertyId/dashboard',
        name: 'properties.property.dashboard',
        component: () => import('./property-dashboard.vue'),
      },
      ...OrgsRoutes,
      ...MantisRoutes,
      ...BeetleRoutes,
      ...MeasurementsRoutes,
    ],
  },
] as RouteRecordRaw[];
