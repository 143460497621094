import { RouteRecordRaw } from 'vue-router';

import MainLayout from './layouts/main.vue';
import HomeRoutes from './modules/home/routes';
import NotificationsRoutes from './modules/notifications/routes';
import OrgRoleRoutes from './modules/org-role/routes';
import PropertyRoleRoutes from './modules/property-role/routes';
import UserRoutes from './modules/user/routes';

export default [
  {

    path: '/org-role',
    component: MainLayout,
    children: [
      // Remove spread operators and comment out undefined routes
      ...HomeRoutes,
      ...OrgRoleRoutes,
      ...NotificationsRoutes,
      ...PropertyRoleRoutes,
      ...UserRoutes,
    ],
  },
] satisfies RouteRecordRaw[];
