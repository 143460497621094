import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '/sysmaster/orgs',
    name: 'sysmaster.orgs',
    component: () => import('./list.vue'),
  },
  {
    path: '/sysmaster/orgs/:orgId',
    name: 'sysmaster.orgs.org',
    redirect: { name: 'sysmaster.orgs.org.home' },
    component: () => import('./layouts/orgs.vue'),
    children: [
      {
        path: '/sysmaster/orgs/:orgId/home',
        name: 'sysmaster.orgs.org.home',
        component: () => import('./home.vue'),
      },
    ],
  },
] satisfies RouteRecordRaw[];
