/* eslint-disable @typescript-eslint/no-explicit-any */
import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/unit-control',
    name: 'properties.property.orgs.unit-control',
    component: () => import('./unit-control-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/unit-control/:id',
    name: 'properties.property.orgs.unit-control.unit',
    component: () => import('./unit-control-item.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/integrations',
    name: 'properties.property.orgs.integrations',
    component: () => import('./integrations-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/integrations/new',
    name: 'properties.property.orgs.integrations.new',
    component: () => import('./integrations-new.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/integrations/:id',
    name: 'properties.property.orgs.integrations.integration',
    component: () => import('./integrations-item.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/automation',
    name: 'properties.property.orgs.automation',
    redirect: { name: 'properties.property.orgs.automation.tickets' },
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/automation/tickets',
    name: 'properties.property.orgs.automation.tickets',
    component: () => import('./automation-tickets.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/automation/alarms',
    name: 'properties.property.orgs.automation.alarms',
    component: () => import('./automation-alarms-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/automation/alarms/:id',
    name: 'properties.property.orgs.automation.alarms.rule',
    component: () => import('./automation-alarms-item.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/automation/tasks',
    name: 'properties.property.orgs.automation.tasks',
    component: () => import('./automation-recurring-tasks-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/automation/tasks/:id',
    name: 'properties.property.orgs.automation.tasks.task',
    component: () => import('./automation-recurring-tasks-item.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/inventory',
    name: 'properties.property.orgs.inventory',
    redirect: { name: 'properties.property.orgs.inventory.units' },
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/inventory/units',
    name: 'properties.property.orgs.inventory.units',
    component: () => import('./inventory-units-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/inventory/units/:id',
    name: 'properties.property.orgs.inventory.units.unit',
    component: () => import('./inventory-units-item.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/inventory/devices',
    name: 'properties.property.orgs.inventory.devices',
    component: () => import('./inventory-devices-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/inventory/devices/:id',
    name: 'properties.property.orgs.inventory.devices.device',
    component: () => import('./inventory-devices-item.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/inventory/technical-systems',
    name: 'properties.property.orgs.inventory.technical-systems',
    component: () => import('./inventory-technical-systems-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/inventory/technical-systems/:id',
    name: 'properties.property.orgs.inventory.technical-systems.technical-system',
    component: () => import('./inventory-technical-systems-item.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/inventory/tags',
    name: 'properties.property.orgs.inventory.tags',
    component: () => import('./inventory-tags-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/inventory/external-companies',
    name: 'properties.property.orgs.inventory.external-companies',
    component: () => import('./inventory-external-companies-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/inventory/external-companies/:id',
    name: 'properties.property.orgs.inventory.external-companies.external-company',
    component: () => import('./inventory-external-companies-item.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/inventory/external-companies/:externalCompanyId/external-partners/:id',
    name: 'properties.property.orgs.inventory.external-companies.external-company.external-partners.external-partner',
    component: () => import('./inventory-external-partners-item.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/settings',
    name: 'properties.property.orgs.settings',
    redirect: { name: 'properties.property.orgs.settings.general' },
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/settings/general',
    name: 'properties.property.orgs.settings.general',
    component: () => import('./property-settings-general.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/settings/managers',
    name: 'properties.property.orgs.settings.managers',
    component: () => import('./property-settings-managers-list.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/settings/managers/:id',
    name: 'properties.property.orgs.settings.managers.manager',
    component: () => import('./property-settings-managers-item.vue'),
  },
  {
    path: '/property-role/orgs/:orgId/properties/:propertyId/settings/managers/:id/approve',
    name: 'properties.property.orgs.settings.managers.manager.approve',
    component: () => import('./property-settings-managers-item-approve.vue'),
  },
] as RouteRecordRaw[];
